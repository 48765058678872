// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infos-cgu-js": () => import("./../../../src/pages/infos/CGU.js" /* webpackChunkName: "component---src-pages-infos-cgu-js" */),
  "component---src-pages-infos-faq-js": () => import("./../../../src/pages/infos/FAQ.js" /* webpackChunkName: "component---src-pages-infos-faq-js" */),
  "component---src-pages-infos-legal-mentions-js": () => import("./../../../src/pages/infos/LegalMentions.js" /* webpackChunkName: "component---src-pages-infos-legal-mentions-js" */),
  "component---src-pages-infos-politique-js": () => import("./../../../src/pages/infos/Politique.js" /* webpackChunkName: "component---src-pages-infos-politique-js" */)
}

